@import '~@astrid/components/dist/scss/utils';

.achievement-popup {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: -100%;
  left: 50%;
  transform: translate(-50%);
  background-color: white;
  border-radius: 20px;
  visibility: hidden;
  transition: all 500ms;
  z-index: 2000;
  padding: 24px 48px 24px 32px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  & > svg {
    margin-right: 20px;
    width: 75px;
    height: 80px;
    animation: spinner 2s infinite linear;
    transform-style: preserve-3d;
  }

  & > div {
    h4 {
      margin: 4px 0 10px;
      color: $cobalt-blue;
    }

    p {
      margin: 0;
    }
  }

  button {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    padding: 4px;
    border-radius: 100px;
    transition: 250ms all;
    & > svg {
      width: 100%;
      height: 100%;
      fill: $black-76;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  @include breakpoint(xs) {
    padding: 16px 40px 16px 24px;
  }

  &--open {
    @extend .achievement-popup;
    visibility: visible;
    bottom: 20px;
  }
}
