@import '~@astrid/components/dist/scss/utils';

.email-confirmed {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('../../resources/graphics/confirmation_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-x: 86%;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  padding: 20px 0 40px 0;

  @include breakpoint(xs) {
    justify-content: space-between;
  }

  &__logo {
    position: absolute;
    top: 2vw;
    left: 3vw;
    height: 32px;
    width: auto;

    @include breakpoint(xs) {
      position: inherit;
    }
  }

  &__button {
    color: $black-76;
    padding: 16px 20px;
    font-size: 18px;
    background-color: $maize;
    border-radius: 16px;
    margin-bottom: 4px;
    box-shadow: 0 4px darken($maize, 25%);

    &:active {
      transform: translateY(2px);
      box-shadow: 0 2px darken($maize, 25%);
    }
  }
}
