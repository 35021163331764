@import '~@astrid/components/dist/scss/utils';

.mobile-app-button {
  background-color: $maize;
  border-radius: 12px;
  padding: 1.25rem 1.5rem;
  color: black;
  transition: 250ms all;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
  &:hover {
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
  }
}
